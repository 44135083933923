import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { api } from '../../services/api';
import { navigate } from 'gatsby';
import { isMobile } from 'react-device-detect';
import MobileNotSupport from '../../components/MobileNotSupport';

import { setDataLocalByKey, ACCESS_TOKEN, EMAIL, FULL_NAME } from '../../utils/sessionstorage';
import { Backdrop, CircularProgress } from '@material-ui/core';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://staging.travelpix.io">
                TravelPix
        </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: theme.palette.secondary.light,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const signInPage = () => {
    const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const classes = useStyles();
    const [openLoading, setOpenLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (email === '') {
            setEmailError('This field is required');
        } else if (!emailRegrex.test(email)) {
            setEmailError('Please input a valid email');
        } else {
            setEmailError('');
        }

        if (password === '') {
            setPasswordError('This field is required');
        } else {
            setPasswordError('');
        }

        if (emailError === '' && passwordError === '') {
            setOpenLoading(true);

            api({
                method: 'post',
                url: `/auth/login`,
                data: {
                    email: email,
                    password: password
                }
            }).then(response => {
                setOpenLoading(false);

                if ('success' in response.data && response.data.success === true) {
                    setDataLocalByKey(ACCESS_TOKEN, response.data.data.accessToken);
                    setDataLocalByKey(EMAIL, response.data.data.email);
                    setDataLocalByKey(FULL_NAME, response.data.data.firstName + " " + response.data.data.lastName);
                    navigate('/');
                } else {
                    setEmailError(response.data.message);
                }
            }).catch(error => {
                setOpenLoading(false);
                setEmailError('An error ocurred. Please try again.')
            });
        }
    }

    if (isMobile) {
        return (
            <MobileNotSupport />
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Backdrop className={classes.backdrop} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
          </Typography>
                <form className={classes.form} onSubmit={handleSubmit} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoFocus
                        value={email}
                        onInput={e => setEmail(e.target.value)}
                        error={emailError !== '' ? true : false}
                        helperText={emailError}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onInput={e => setPassword(e.target.value)}
                        error={passwordError !== '' ? true : false}
                        helperText={passwordError}
                    />
                    <Link color="inherit" href="/forgot-password">
                        Forgot password?
                    </Link>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default signInPage;