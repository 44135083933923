const ACCESS_TOKEN = 'ACCESS_TOKEN';
const EMAIL = 'EMAIL';
const FULL_NAME = 'FULL_NAME';

const setDataLocalByKey = (key: string, data: string) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, data);
    }
};

const removeAllDataLocal = () => {
    if (typeof window !== 'undefined') {
        sessionStorage.clear();
    }
}

const getDataLocalByKey = (key: string): string => {
    if (typeof window !== 'undefined') {
        const dataStr = sessionStorage.getItem(key);
        if (dataStr === null) return '';
        return dataStr;
    }
    return '';
};

export { ACCESS_TOKEN, EMAIL, FULL_NAME, setDataLocalByKey, getDataLocalByKey, removeAllDataLocal };